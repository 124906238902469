import { Grid, Typography } from '@mui/material'
import { FacebookEmbed, TwitterEmbed } from 'react-social-media-embed'
import { GenericCardHeader } from '../../Components/GenericCardComponents'
import YoutubePlayer from '../../Components/YoutubePlayer'

type Props = {
    smDown?: boolean
}
const SocialPage = ({ smDown }: Props) => {
    return (
        <Grid
            container
            sx={{
                height: smDown ? undefined : '100%',
                borderTop: 1,
                borderBottom: 1,
                borderColor: 'lightgrey',

                paddingBottom: 2,
            }}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Grid item xs={6}>
                {/* <GenericCardHeader title="Social Media" /> */}
                <YoutubePlayer videoId="mRbDHq7vM2k" />
            </Grid>
            {/*             <Grid item container>
                <Grid item xs={12} md={6}>
                    <TwitterCard smDown={smDown} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <FaceBookCard smDown={smDown} />
                </Grid>
            </Grid> */}
        </Grid>
    )
}

export default SocialPage

const TwitterCard = ({ smDown }: Props) => {
    return (
        <Grid container sx={{ textAlign: 'center' }} justifyContent={'center'}>
            <Grid item xs={12}>
                <Typography
                    variant="h4"
                    sx={{ paddingBottom: 2, fontFamily: 'Bodoni' }}
                >
                    Twitter
                </Typography>
            </Grid>

            <Grid item xs={12} sm={8} sx={{ display: 'flex' }}>
                <TwitterEmbed
                    url="https://twitter.com/PixelAndBracket/status/1356633038717923333"
                    width={'100%'}
                />
            </Grid>
        </Grid>
    )
}

const FaceBookCard = ({ smDown }: Props) => {
    return (
        <Grid
            container
            sx={{ textAlign: 'center', pt: smDown ? 4 : 0 }}
            justifyContent={'center'}
        >
            <Grid item xs={12}>
                <Typography
                    variant="h4"
                    sx={{ paddingBottom: 2, fontFamily: 'Bodoni' }}
                >
                    Facebook
                </Typography>
            </Grid>

            <Grid item xs={12} sm={8} sx={{ display: 'flex' }}>
                <FacebookEmbed
                    url="https://www.facebook.com/andrewismusic/posts/451971596293956"
                    width={'100%'}
                />
            </Grid>
        </Grid>
    )
}
