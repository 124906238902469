import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { GenericCardHeader } from '../../../Components/GenericCardComponents'
import co_so_bao from '../../../Resources/Orphanages/co_so_bao_tro_xa_hoi_thien_duyen.png'
import orphans_in_bed from '../../../Resources/Orphans/metal_beds_of_orphans_2.png'
import ImageCarousel from '../../../Components/Carousel'
import ResponsiveImage from '../../../Components/ResponsiveImage'
import useBreakPointDebugger from '../../../Functions/useBreakPointDebugger'

const OrphanageInfo = () => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('md'))

    useBreakPointDebugger()
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ pt: 6, overflow: 'auto' }}
            direction={smDown ? 'row-reverse' : 'row'}
        >
            {smDown && (
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent={smDown ? undefined : 'end'}
                    sx={{ height: smDown ? undefined : '100%' }}
                >
                    <ResponsiveImage label="img" source={co_so_bao} />
                </Grid>
            )}

            <Grid item xs={12} md={5} lg={6} container sx={{ pl: 4, pr: 4 }}>
                <Grid item xs={12}>
                    <GenericCardHeader title="Mai Am Thien Duyen" />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        fontSize: 24,
                        fontFamily: 'times-new-roman',
                        overflow: 'auto',
                    }}
                >
                    Orphanages of Vietnam, with the assistance of relatives and
                    friends in Vietnam, discovered an orphanage in a rural area
                    named Mai Am Thien Duyen. The orphanage is not widely known,
                    so they lack sufficient financial support. Founded in 1988,
                    it is currently managed by Tran Thi Cam Giang, also known as
                    Ma Muoi.
                    <br />
                    <br />
                    Mai Am Thien Duyen currently cares for 125 children and
                    adults, some with special needs. In addition to providing
                    food, medical care, and shelter, the orphanage also creates
                    opportunities for the children to attend public school, with
                    40 currently enrolled.
                    <br />
                    <br />
                    In order to provide for the children, the orphanage has
                    limited abilities and resources to generate income, such as
                    selling lottery tickets and growing their own vegetables.
                    <br />
                    <br />
                    This place requires a tremendous amount of help to sustain
                    their day-to-day activities.
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                md={7}
                lg={6}
                container
                justifyContent={smDown ? undefined : 'end'}
                sx={{ height: smDown ? undefined : '100%' }}
            >
                {smDown ? null : (
                    <>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                pb: 4,
                            }}
                        >
                            <img
                                src={co_so_bao}
                                alt="Orphanage"
                                style={{
                                    width: '70%',
                                    objectFit: 'contain',
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                src={orphans_in_bed}
                                alt="Orphanage"
                                style={{
                                    width: '70%',
                                    objectFit: 'contain',
                                }}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    )
}

export default OrphanageInfo
