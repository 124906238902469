import { Grid, Typography } from '@mui/material'
import ImageCarousel from '../../../Components/Carousel'
import placeholder from '../../../Resources/Orphans/metal_beds_oprhans.png'
import placeholder2 from '../../../Resources/Orphans/three_orphans_on_ipad.png'
import placeholder3 from '../../../Resources/Orphans/metal_beds_of_orphans_2.png'
import placeholder4 from '../../../Resources/Orphans/red_flower_shirt_orphan.png'

const InformationPage = () => {
    return (
        <Grid container>
            <Grid
                item
                xs={12}
                sx={{
                    padding: 10,
                    fontSize: 20,
                    overflow: 'auto',
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ fontFamily: 'times-new-roman', fontSize: 22 }}
                >
                    At Mai Am Thien Duyen, there are 125 children and adults.
                    Among them, 82 orphans have various types of brain damage,
                    such as cerebral palsy, a neurological condition that can
                    manifest as issues with muscle tone, posture, and/or
                    movement disorders. Some of the adults at the orphanage have
                    been there since childhood and have lived most, if not all,
                    of their lives at the orphanage. Despite their
                    circumstances, some adults were able to attend medical
                    school and become doctors, while others were able to get
                    married and lead normal lives.
                </Typography>

                <br />
                <br />
                <Typography
                    variant="h6"
                    sx={{ fontFamily: 'times-new-roman', fontSize: 22 }}
                >
                    The orphanage is struggling to meet the needs of the
                    disabled orphans because they do not have enough money and
                    resources to provide adequate care for all of the children.
                    The children lack sufficient clothing or food during times
                    of hardship, and they also do not have mattresses to sleep
                    on.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ImageCarousel
                    images={[
                        placeholder,
                        placeholder2,
                        placeholder3,
                        placeholder4,
                    ]}
                />
            </Grid>
        </Grid>
    )
}

export default InformationPage
