import { Grid, List, ListItem } from '@mui/material'
import { GenericCardHeader } from '../../Components/GenericCardComponents'
import { DateCalendar } from '@mui/x-date-pickers'
import { CalendarEvent } from '../../Components/ComponentType'

type Props = {
    smDown?: boolean
}
const UpcomingEvents = ({ smDown }: Props) => {
    const eventsList: CalendarEvent[] = [
        {
            eventName: 'Zoom Meeting',
            date: 'TBD',
            location: 'New Jersey',
        },
    ]

    return (
        <Grid
            container
            sx={{
                backgroundColor: '#DDE4E6',
                paddingTop: smDown ? 4 : 0,
            }}
            justifyContent={'center'}
        >
            <Grid
                item
                xs={12}
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                }}
            >
                <GenericCardHeader
                    title="Upcoming Events"
                    fontSize={smDown ? 50 : 60}
                />
            </Grid>
            <Grid item xs={12} container>
                <Grid item xs={12} md={8}>
                    <DateCalendar />
                </Grid>
                <Grid item xs={4}>
                    <EventList eventsList={eventsList} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UpcomingEvents

type EventProp = {
    eventsList: CalendarEvent[]
}
const EventList = ({ eventsList }: EventProp) => {
    return (
        <List>
            {eventsList.map((item) => (
                <ListItem>
                    Event name: {item.eventName}
                    <br />
                    Date: {item.date}
                    <br />
                    Location: {item.location}
                </ListItem>
            ))}
        </List>
    )
}
