import { Box, Grid, Typography } from '@mui/material'
import orphansOnBench from '../../../Resources/Orphans/orphans_on_bench.png'
import { ThemedRouteButton } from '../../../Components/Button'
import { useThemeBreakPoints } from '../../../Functions/useBreakPointDebugger'
import ResponsiveImage from '../../../Components/ResponsiveImage'

type Props = {
    smDown?: boolean
}
const OpeningPage = ({ smDown }: Props) => {
    const { xlMatch, lgMatch, xsMatch } = useThemeBreakPoints()

    return (
        <Grid
            container
            sx={{ height: smDown ? undefined : '100%', overflowX: 'auto' }}
        >
            {smDown ? (
                <>
                    <Grid item>
                        <ResponsiveImage
                            label="orphans on a bench"
                            source={orphansOnBench}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                        }}
                    >
                        <AboutChildrenCard smDown xsMatch={xsMatch} />
                    </Grid>
                </>
            ) : (
                <Grid
                    item
                    xs={12}
                    sx={{
                        height: '100%',
                        backgroundImage: `url(${orphansOnBench})`,
                        backgroundSize: 'cover',
                        backgroundPosition: lgMatch
                            ? 'center top -100px'
                            : xlMatch
                            ? 'center top -200px'
                            : undefined,
                    }}
                    container
                    justifyContent={'flex-end'}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            pb: 8,
                        }}
                    >
                        <AboutChildrenCard />
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}
export default OpeningPage

type ChildrenCardProp = {
    smDown?: boolean
    xsMatch?: boolean
}
const AboutChildrenCard = ({ smDown, xsMatch }: ChildrenCardProp) => {
    /* const size = '450px' */
    return (
        <Box
            sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                width: 512,
                height: 200,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 3,
            }}
        >
            <Typography
                variant={smDown ? (xsMatch ? 'h5' : 'h4') : 'h4'}
                sx={{
                    fontFamily: 'Bodoni',
                    fontWeight: 'bold',
                    paddingBottom: 2,
                    textAlign: smDown ? 'center' : undefined,
                }}
            >
                About the Orphans
            </Typography>
            <Typography
                variant="h6"
                sx={{
                    fontSize: smDown ? 20 : 22,
                    pb: 2,
                    fontFamily: 'times-new-roman',
                }}
            >
                There are numerous orphanages in Vietnam that provide care for
                the young, old, disabled, and unfortunate.
            </Typography>
            <div
                style={{
                    marginBottom: '4px',
                    width: '50%',
                }}
            >
                <ThemedRouteButton title="Donate" routePath="/donate" />
            </div>
        </Box>
    )
}
