import { Grid, Typography } from '@mui/material'
import { GenericCardHeader } from '../../../Components/GenericCardComponents'
import babyPhoto from '../../../Resources/Racheal/Rachael_Baby.png'
import ResponsiveImage, {
    CoverImage,
} from '../../../Components/ResponsiveImage'

type Prop = {
    smDown?: boolean
}
const CreationPage = ({ smDown }: Prop) => {
    return (
        <Grid
            container
            sx={{
                paddingBottom: smDown ? 4 : 4,
            }}
            justifyContent={'center'}
        >
            {smDown ? (
                <Grid
                    item
                    xs={12}
                    sx={{
                        paddingRight: smDown ? 0 : 5,
                    }}
                >
                    <ResponsiveImage label="photo" source={babyPhoto} />
                </Grid>
            ) : null}
            <Grid
                item
                xs={12}
                container
                justifyContent={'center'}
                sx={{
                    textAlign: smDown ? 'center' : undefined,
                }}
            >
                <GenericCardHeader
                    title="How I started Orphanages of Vietnam"
                    fontSize={smDown ? 25 : 50}
                />
            </Grid>

            <Grid
                item
                xs={10}
                md={12}
                sx={{
                    paddingTop: smDown ? 6 : 0,
                    pr: 2,
                }}
                container
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Grid
                    item
                    xs={12}
                    md={7}
                    lg={4}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                >
                    <BioText smDown={smDown} />
                </Grid>
                {smDown ? null : (
                    <Grid item xs={5} sx={{ height: '100%' }}>
                        <CoverImage label="photo" source={babyPhoto} />
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

export default CreationPage

type BioTextProp = {
    smDown?: boolean
}
const BioText = ({ smDown }: BioTextProp) => (
    <div style={{ padding: smDown ? 0 : 5 }}>
        <Typography
            variant={'h6'}
            sx={{ fontSize: smDown ? 18 : 22, fontFamily: 'times-new-roman' }}
        >
            When I was 8 months old, a kind and caring couple living in the
            United States adopted me. We flew back to the states together, and
            from the moment we touched down, there was a huge celebration. I
            still have pictures from that day, and I'm forever grateful to have
            been matched with my parents. They told me that being adopted was
            something unique and something to be proud of, and I was raised with
            that mindset. For the next 16 years, they always reminded me that I
            was special and a blessing in this world.
            <br />
            <br />
            If it weren’t for my adoptive parents, I would still be in the
            orphanage. I couldn’t imagine not having food on my table or a good
            education, and most of all, loving parents and a caring family.
            That’s why I took the initiative to start this nonprofit
            organization, Orphanages of Vietnam. I want to raise awareness for
            the kids in the orphanages who need help. By fundraising for the
            orphanages, I am able to connect with people who share the same
            passion as me.
        </Typography>
    </div>
)
