import { Avatar, Box, Grid } from '@mui/material'
import { GenericCardHeader } from '../../../Components/GenericCardComponents'

type Props = {
    smDown?: boolean
}
const Facts = ({ smDown }: Props) => {
    return (
        <Grid
            container
            sx={{
                backgroundColor: '#DDE4E6',
                paddingTop: smDown ? 3 : 4,
                paddingBottom: smDown ? 4 : 4,
            }}
            justifyContent={'center'}
        >
            <GenericCardHeader
                title="Some Fun Facts About Me!"
                fontSize={smDown ? 30 : 50}
            />
            <ListOfFacts smDown={smDown} />
        </Grid>
    )
}

export default Facts

const ListOfFacts = ({ smDown }: Props) => {
    const facts = [
        'I have two birds named Cher and Snowy!',
        'My favorite subject in school is English',
        'I love rollerblading and ice skating',
        'My favorite seasons are fall/winter!',
        'One of my favorite hobbies is knitting!',
        'My favorite holiday is Christmas',
    ]

    const factsLength = facts.length / 2

    const halfFacts = facts.slice(0, facts.length / 2)
    const lastHalfFacts = facts.slice(facts.length / 2)

    return (
        <Grid container>
            {smDown ? (
                <Grid item xs={12}>
                    {facts.map((item, index) => (
                        <Grid
                            key={`${item}`}
                            item
                            xs={12}
                            container
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{ paddingBottom: 2 }}
                        >
                            <Avatar sx={{ marginRight: 2 }}>{index + 1}</Avatar>
                            <Box
                                sx={{
                                    backgroundColor: 'whitesmoke',
                                    padding: 2,
                                    fontSize: 15,
                                    fontFamily: 'sans-serif',
                                    width: '70%',
                                }}
                            >
                                {item}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <>
                    <Grid item xs={6} container>
                        {halfFacts.map((item, index) => (
                            <FactsBox
                                key={`${item}`}
                                item={item}
                                index={index}
                            />
                        ))}
                    </Grid>
                    <Grid item xs={6} container>
                        {lastHalfFacts.map((item, index) => (
                            <FactsBox
                                key={`${item}`}
                                item={item}
                                index={index}
                                factsLength={factsLength}
                            />
                        ))}
                    </Grid>
                </>
            )}
        </Grid>
    )
}

type FactsBoxProps = {
    item: string
    index: number
    factsLength?: number
}
const FactsBox = ({ index, item, factsLength }: FactsBoxProps) => {
    return (
        <Grid
            key={`${item}`}
            item
            xs={12}
            container
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ mb: 2 }}
        >
            <Avatar sx={{ marginRight: 5 }}>
                {factsLength ? index + 1 + factsLength : index + 1}
            </Avatar>
            <Box
                sx={{
                    backgroundColor: 'whitesmoke',
                    padding: 5,
                    fontSize: 20,
                    fontFamily: 'sans-serif',
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {item}
            </Box>
        </Grid>
    )
}
