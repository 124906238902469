import { Grid } from '@mui/material'
import { GenericCardHeader } from '../../../Components/GenericCardComponents'

type Prop = {
    smDown?: boolean
}
const MissionStatement = ({ smDown }: Prop) => {
    return (
        <Grid
            container
            sx={{
                height: smDown ? undefined : '30%',
                backgroundColor: '#DDE4E6',
                pt: smDown ? 4 : undefined,
            }}
            justifyContent={'center'}
            alignItems={'center'}
            direction={'column'}
        >
            <Grid item>
                <GenericCardHeader title="Mission Statement" fontSize={40} />
            </Grid>
            <Grid
                item
                sx={{
                    fontFamily: 'times-new-roman',
                    fontSize: smDown ? 22 : 28,
                    textAlign: smDown ? 'center' : 'center',
                    pb: smDown ? 4 : undefined,
                    pl: smDown ? 2 : 2,
                    pr: smDown ? 2 : 2,
                    overflow: 'auto',
                }}
            >
                To build a community passionate about supporting and improving
                the lives of less fortunate children in orphanages in Vietnam.
            </Grid>
        </Grid>
    )
}

export default MissionStatement
