import { Grid, Typography } from '@mui/material'
import toddlerPhoto from '../../../Resources/Racheal/Rachael_Child.png'
import ResponsiveImage from '../../../Components/ResponsiveImage'

type Prop = {
    smDown?: boolean
    xsOnly?: boolean
    mdOnly?: boolean
}
const PhotoBio = ({ smDown, xsOnly, mdOnly }: Prop) => {
    return (
        <Grid
            container
            sx={{
                backgroundColor: '#DDE4E6',
            }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    paddingLeft: smDown ? 0 : 2,
                    backgroundColor: 'white',
                }}
                container
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Grid item xs={12} md={6} sx={{ backgroundColor: 'white' }}>
                    <ResponsiveImage label="photo" source={toddlerPhoto} />
                </Grid>
                {smDown ? null : (
                    <Grid
                        item
                        xs={6}
                        sx={{
                            backgroundColor: 'whitesmoke',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                backgroundColor: 'whitesmoke',
                                padding: 5,
                                textAlign: 'center',
                                fontSize: '4vw',
                                fontFamily: 'Bodoni',
                            }}
                        >
                            Rachael Thai, the founder of Orphanages of Vietnam
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    padding: smDown ? 0 : 10,
                    paddingTop: smDown ? 4 : 2,
                }}
                container
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Grid item xs={12}>
                    {smDown ? (
                        <Typography
                            variant="h4"
                            sx={{
                                backgroundColor: 'whitesmoke',
                                padding: 1,
                                textAlign: 'center',
                                fontSize: xsOnly ? '7vw' : '5vw',
                                fontFamily: 'Bodoni',
                            }}
                        >
                            Hi! I'm Rachael Thai and I'm the founder of
                            Orphanages of Vietnam
                        </Typography>
                    ) : (
                        <></>
                    )}
                </Grid>
                <Grid
                    item
                    xs={10}
                    sx={{
                        paddingTop: smDown ? 5 : 2,
                        paddingBottom: smDown ? 4 : 0,
                    }}
                >
                    {smDown ? (
                        <Typography
                            variant="h4"
                            sx={{
                                fontFamily: 'Bodoni',
                                textAlign: 'center',
                                paddingBottom: 4,
                            }}
                        >
                            About Me
                        </Typography>
                    ) : null}
                    <Typography
                        sx={{
                            fontSize: smDown ? 16 : mdOnly ? '2vw' : '1.8vw',
                            fontFamily: 'times-new-roman',
                        }}
                    >
                        {smDown ? null : 'Hi, I’m Rachael, and'} I was born in
                        Vietnam and raised in New Jersey. Currently a junior, I
                        am interested in pursuing a career in the healthcare
                        field. In my free time, I enjoy scrapbooking,
                        journaling, and watching movies, especially when it
                        rains. During winter, I love sledding in my backyard,
                        baking goods with my friends, and making gingerbread
                        houses!
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PhotoBio
