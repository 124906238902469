import { Card, CardActions, CardContent, CardMedia } from '@mui/material'
import { ThemedRouteButton } from '../../../../Components/Button'
import { GenericCardHeader } from '../../../../Components/GenericCardComponents'
import orphanage from '../../../../Resources/Orphanages/mai_am_thien_duyen.png'
const IntroCard = () => {
    return (
        <Card sx={{ minHeight: 400 }}>
            <CardMedia
                sx={{ height: 340, overflowX: 'auto' }}
                image={orphanage}
                title="Mai Am Thien Duyen"
            />
            <GenericCardHeader title="About the Orphanage" />
            <CardContent
                sx={{
                    textAlign: 'center',
                    fontSize: 22,
                    fontFamily: 'times-new-roman',
                }}
            >
                Mai Am Thien Duyen is an orphanage in Vietnam that provides care
                for the young, old, disabled, and less fortunate.
            </CardContent>
            <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <ThemedRouteButton
                    title="Donate"
                    routePath="/donate"
                    width="200px"
                />
            </CardActions>
        </Card>
    )
}

export default IntroCard
